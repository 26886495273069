<script>
import { resolveImageUrl } from '@/imageResolver.js'

export default {
  name: 'gallery',

  props: {
    value: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      images: this.value,
    };
  },

  watch: {
    value(val) {
      this.images = val;
    },
  },

  methods: {
    resolveImageUrl,
  },
};
</script>

<template>
  <div class="flex flex-wrap mb-4">
    <!-- {{ images }} -->
    <localized-link
      :to="{ name: 'image', params: { extId: image.ext_id, headline: image.html_title.toLowerCase() } }"
      class="w-full sm:w-1/2 md:w-1/4 mb-8 cursor-pointer"
      v-for="(image, i) in images"
      :key="i"
    >
      <div class="mx-auto flex justify-center">
        <img
          style="object-position: bottom"
          class="w-56 h-56 sm:w-46 sm:h-46 md:w-40 md:h-40 max-w-full max-h-full object-contain"
          :src="resolveImageUrl(image.ext_id, 'mini')"/>
      </div>
      <div class="px-4">
        <p class="imageHeadline">
          {{ image.headline }}
        </p>
      </div>
    </localized-link>
  </div>
</template>

<style lang="scss" scoped>
.imageHeadline {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding: 7px 0;
}
</style>
