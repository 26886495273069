<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { translateLink } from '@/misc';
import Gallery from '@/components/gallery/Gallery.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import FilterBar from "./components/FilterBar";
import MobileFilterBar from "./components/MobileFilterBar";
import {i18n} from "vue-lang-router";


export default {
  name: 'library-search',

  data() {
    return {
      searchInitialized: false,
      query: null,
      error: null,
      pageInfo: null,
      limit: +localStorage.getItem('imagesPerPage') || 20,
      isCreating: false,
      page: 1
    };
  },

  async created() {
    // isCreating makes sure that some watchers wait until
    // the component is initialized before starting.
    this.isCreating = true;
    this.query = this.$route.query.q;
    this.page = this.$route.query.page || 1;
    this.limit = +localStorage.getItem('imagesPerPage') || 20;
    document.title = i18n.t("tempus");



    if (this.query) {
      await this.getData();
      this.isCreating = false;
    } else {
      //this.$router.push({ name: 'library-start' });
    }
  },

  computed: {
    images() {
      return this.pageInfo ? this.pageInfo.data : [];
    },

    currentPage() {
      return parseInt(this.$route.query.page, 10) || 1;
    },
  },

  watch: {
    category(val) {
      if (val.info_sv) {
        this.translateInfo(val);
      }

      if (val.limitation_info_sv) {
        this.translateLimitationInfo(val);
      }
    },

    limit() {
      if (this.isCreating) return;

      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: 1,
        },
      });
    },
  },

  methods: {
    getData() {
      this.isLoading = true;
      let baseUrl = `${process.env.VUE_APP_BACKEND_URL}/api/images/search/` + this.query + '/' + this.limit + '/' + (+this.page - 1);
      axios.get(baseUrl).then((res) => {
        this.isLoading = false;
        this.pageInfo = res.data;
      });
    },
    nextPage() {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: +this.page + 1,
        },
      });
    },

    prevPage() {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: +this.page - 1,
        },
      });
    },

    getPluralSingular() {
      return this.pageInfo?.total > 1 ? 'träffar' : 'träff';
    },
  },

  components: {
    FilterBar,
    Gallery,
    Breadcrumbs,
    MobileFilterBar,
  },
};
</script>

<template>
  <div class="flex flex-col md:flex-row">
    <div class="hidden md:block md:max-w-xs">
      <filter-bar
          v-if="query"
          :query="query"
          :limit.sync="limit"
      />
    </div>

    <div class="h-full w-full md:hidden">
      <MobileFilterBar
          v-if="query"
          :query="query"
          :limit.sync="limit"
      />
    </div>
    <div class="flex-1 bg-white">
      <div class="flex flex-col justify-between text-left p-10" v-if="query">
        <div>
          <h1 class="text-2xl keyword-title">Sökresultat</h1>
          <p class="text-sm pb-4 italic keyword-description" v-if="pageInfo?.total > 0">
            {{$t("yourSearchFor")}} <span>”{{query}}”</span> {{ $t("gave") }} {{ pageInfo?.total }} {{ $tc("match", pageInfo?.total) }}.
          </p>
          <p class="text-sm pb-4 italic" v-if="pageInfo?.total === 0">
            {{$t("yourSearchFor")}} <span>”{{query}}”</span> {{$t("noMatches")}}.
          </p>
        </div>
      </div>

      <gallery class="gallery-view w-full" v-if="pageInfo" v-model="pageInfo.images"></gallery>

      <div class="page-handle flex justify-center my-8" v-if="pageInfo.images.length > 0">
        <button
            class="text-2xl"
            :disabled="+page === 1"
            @click="prevPage()"
        >
          <i class="fas fa-chevron-left prev-page-icon"></i>
        </button>
        <div class="info text-2xl mx-4 inline-block">
          <span class="info"> {{ page }} av {{ Math.ceil(pageInfo.total / this.limit) }} </span>
        </div>
        <button
            class="text-2xl"
            :disabled="+page === (Math.ceil(pageInfo.total / this.limit))"
            @click="nextPage()"
        >
          <i class="fas fa-chevron-right next-page-icon"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.info-text {
  a {
    color: #4299e1 !important;
    text-decoration: underline !important;
  }
}

.keyword-title{
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 12px;
}

.keyword-description{
  font-size: 16px;
  font-weight: 500;
  line-height: 29px;
}

.keyword-title:first-letter {
  text-transform: uppercase;


}
</style>
