<script>
import { orderCategories } from '@/misc.js';

export default {
  name: 'filter-bar',

  props: {
    query: {
      type: String,
      required: true,
    },

    limit: {
      type: Number,
      default: 20,
    },
  },

  data() {
    let imagesPerPage = 20;
    if (localStorage.getItem('imagesPerPage')) {
      imagesPerPage = +localStorage.getItem('imagesPerPage');
    }
    return {
      isExpanded: false,
      imagesPerPage,
    };
  },

  computed: {

  },

  watch: {
    imagesPerPage(val) {
      localStorage.setItem('imagesPerPage', val);
      this.$emit('update:limit', val);
    },
  },

  methods: {

    /**
     * Note:
     * These functions are used instead of just height
     * to be able to animate the height of the list.
     */
    expand() {
      /**
       * Note:
       * 120 is to be sure to expand all the content,
       * each row is, 40 font-height + padding, 120 should manage even
       * if a category has very long text.
       */
      const maxHeight = this.filteredCategories.length * 120;
      const el = this.$refs.subCategoriesList;
      el.style.maxHeight = `${maxHeight}px`;

      this.isExpanded = true;
    },

    collapse() {
      const el = this.$refs.subCategoriesList;
      el.style.maxHeight = '0px';

      this.isExpanded = false;
    },
  },
};
</script>

<template>
  <div class="filterbar h-full flex flex-col py-4 bg-gray-200 md:bg-gray-100 p-10">
    <div class="w-full text-left py-2 flex justify-between">
      <p>{{ $t('imagesPerPage') }}</p>
      <select v-model="imagesPerPage">
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
    </div>

  </div>
</template>

<style lang="scss" scoped>
#sub-categories {
  transition: max-height .2s ease-in;
}

 #limitation-text > a {
  color: #3b82f6;
  text-decoration: underline;
}

</style>
